<template>
  <div
    class="column is-12"
  >
    <iframe
      :src="field.properties.basic.isDynamic ? field.properties.basic.dynamicDocument.baseUrl + '/' + field.properties.basic.dynamicDocument.name + '.' + field.properties.basic.dynamicDocument.extension
        : field.properties.basic.document"
      frameBorder="0"
      scrolling="auto"
      height="800px"
      width="100%"
    />
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
</script>
